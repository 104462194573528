import React from 'react';
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
interface SidebarItemProps {
  icon: IconType;
  label: string;
  active?: boolean;
  href: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon: Icon,
  label,
  active,
  href,
}) => {
  return (
    <Link
      to={href}
      className={`
        sidebar-item
        flex
        flex-row
        h-auto
        items-center
        justify-center
        w-full
        gap-x-4
        text-md
        font-medium
        cursor-pointer
        transition
        rounded-xl
        text-neutral-300
        px-2
        py-3
        border-bottom
        ${active ? 'secondary-color' : ''}
        `}
    >
      <Icon size={24} className="icon" />
      <p
        className={`truncate w-full text-left font-bold ${
          label ? 'visible' : 'hidden'
        }`}
      >
        {label}
      </p>
    </Link>
  );
};

export default SidebarItem;
