import React, { useState } from 'react';

const T9Keypad: React.FC = () => {

  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const handleKeyPress = (digit: string): void => {
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + digit);
  };

  const handleDelete = (): void => {
    setPhoneNumber((prevPhoneNumber) =>
      prevPhoneNumber.slice(0, prevPhoneNumber.length - 1)
    );
  };
  const handleCall = (): void => {
    // Handle call here
  };

  const handleReset = (): void => {
    setPhoneNumber('');
  };

  return (
    <div className="container p-4 w-fit mx-auto flex flex-col items-center justify-center font-sans bg-neutral-900 rounded-md">
      <input
        className="h-10 mb-4 px-2 rounded-md border-color text-center"
        type="input"
        onChange={(e) => (setPhoneNumber(e.target.value))}
        value={phoneNumber}
      //   readOnly
      />
      <div className="grid grid-cols-3 gap-2">
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('1')}>1</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('2')}>2</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('3')}>3</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('4')}>4</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('5')}>5</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('6')}>6</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('7')}>7</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('8')}>8</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('9')}>9</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('*')}>*</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('0')}>0</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={() => handleKeyPress('#')}>#</div>
      </div>
      <div className="grid grid-cols-3 gap-2 mt-3">
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={handleDelete}>{`<`}</div>
        <div className="button bg-green-500 hover:bg-neutral-100/20 px-4 py-1 rounded whitespace-nowrap pointer-events-auto cursor-pointer" onClick={handleCall}>Call</div>
        <div className="button bg-neutral-100/10 hover:bg-neutral-100/20 px-6 py-1 rounded pointer-events-auto cursor-pointer" onClick={handleReset}>{`X`}</div>
      </div>
    </div>
  );
}

export default T9Keypad;