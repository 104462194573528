// import { twMerge } from "tailwind-merge"

import React from 'react';

interface BoxProps {
  children: React.ReactNode;
  className?: string;
}

const Box: React.FC<BoxProps> = ({ children, className }) => {
  return (
    <div
      className={`
        primary-color
        h-fit
        w-full
        ${className ? className : ''}
      `}
    >
      {children}
    </div>
  );
};

export default Box;
