/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { CustomersPage } from './pages/CustomersPage/Loadable';
import { CdrPage } from './pages/CdrPage/Loadable';
import { ReportPage } from './pages/ReportPage/Loadable';
import { AccountPage } from './pages/Account/Loadable';
import { WorkspacePage } from './pages/WorkSpace/Loadable';
import { UsersPage } from './pages/UsersPage/Loadable';
import { UserPage } from './pages/UserPage/Loadable';
import { LinkCardPage } from './pages/LinkCard/Loadable';
import { LinkCustomerPage } from './pages/LinkCustomer/Loadable';
import { CompanyPage } from './pages/CompanyPage/Loadable';
import { TasksPage } from './pages/TasksPage/Loadable';
import { AutocallsPage } from './pages/AutocallsPage/Loadable';
import { RulesPage } from './pages/RulesPage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import Sidebar from './components/Sidebar/Sidebar';
import { constans } from './pages/CONSTANS';
import './globals.css';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - DIGITAL CRM"
        defaultTitle="DIGITAL CRM"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Produced by ThienDev" />
      </Helmet>
      <Sidebar>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/customers" element={<CustomersPage />} />
          <Route path="/task" element={<TasksPage />} />
          <Route path="/autocall" element={<AutocallsPage />} />
          <Route path="/customers/:path" element={<WorkspacePage />} />
          <Route path="/cdr" element={<CdrPage />} />
          <Route path="/report" element={<ReportPage />} />
          <Route path="/account/company" element={<CompanyPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/users" element={<UsersPage />} />
          <Route path="/account/users/:path" element={<UserPage />} />
          <Route path="/account/link-card" element={<LinkCardPage />} />
          <Route path="/account/link-card/:path" element={<LinkCustomerPage />} />
          <Route path="*" element={<NotFoundPage />} />
          {/* {constans.map(({path, element}) => (
            <Route path={path} element={<element/>} />
            ))} */}
          {/* <Route path="/:path" element={<HomePage />} /> */}
        </Routes>
      </Sidebar>
      <GlobalStyle />
    </BrowserRouter>
  );
}
