import React from 'react';
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
interface SidebarItemProps {
  icon: IconType;
  label: string;
  active?: boolean;
  href: string;
}

const BottomBarItem: React.FC<SidebarItemProps> = ({
  icon: Icon,
  label,
  active,
  href,
}) => {
  return (
    <Link
      to={href}
      className={`
        sidebar-item
        flex flex-col justify-between items-center gap-2 rounded-md
        ${active ? 'text-green-600' : ''}
        `}
    >
      <Icon size={24} className="icon" />
      <p className="top-bar-button-content font-bold">{label}</p>
    </Link>
  );
};

export default BottomBarItem;
