import React, { Children } from 'react';

interface BottombarProps {
  children: React.ReactNode;
}
const Bottombar: React.FC<BottombarProps> = ({ children }) => {
  return (
    <>
      <div className="w-full h-[69px] border-bottom flex flex-row items-center justify-center">
        {children}
      </div>
    </>
  );
};

export default Bottombar;
