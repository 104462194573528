// @save Item to local Storage
export const saveItem = async (value: any) => {
  if (typeof window !== "undefined") {
    // localStorage.setItem(item, value);
    await Promise.all(
      Object.keys(value).map((key: any) => {
        // console.log(key);
        // console.log(user[key]);
        window.localStorage && window.localStorage.setItem(key, value[key]);
      })
    );
  }
};
// @get Token from local Storage
export const getItem = (item: string): string | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(item);
  }
  return null;
};
// @remove item in local Storage
export const removeItem = async (value: string) => {
  if (typeof window !== "undefined")
    window.localStorage && window.localStorage.removeItem(value);
};
