import React from "react";
import { Link } from "react-router-dom";
import { HiHome } from "react-icons/hi";
interface AlertItemProps {
  image: string;
  text: string;
  name: string;
  href: string;
}
const AlertItem: React.FC<AlertItemProps> = ({ image, name, text, href }) => {
  return (
    <div
      // to={href}
      className="
            relative
            group
            flex
            items-center
            rounded-md
            overflow-hidden
            gap-x-4
            bg-neutral-100/10
            hover:bg-neutral-100/20
            transition
            pr-4
            w-full
        "
    >
      <Link
        to={"/"}
        className="
            overflow-hidden
            relative
            h-[64px]
            min-w-[64px]
            max-w-[80px]

        "
      >
        <img
          src={image}
          alt="logo"
          width="auto"
          height="auto"
          object-fit="cover"
          className="h-full w-full"
        />
      </Link>
      <Link to={href} className="alert-text w-full overflow-hidden">
        <p className="run-text font-medium truncate py-5">{text}</p>
      </Link>
    </div>
  );
};

export default AlertItem;
