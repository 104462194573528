import React, { Children } from 'react';

interface TopbarProps {
  children: React.ReactNode;
}
const Topbar: React.FC<TopbarProps> = ({ children }) => {
  return (
    <>
      <div className="w-full h-[90px] flex flex-row items-center justify-center">
        {children}
      </div>
    </>
  );
};

export default Topbar;
