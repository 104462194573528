import { AiFillPhone } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import T9Keypad from "./T9Keypad";
import React from "react";
const PhoneApp = () => {
  const onClick = () => {
    // Handle upload later
  };
  return (
    <div className="flex flex-col">
      <div
        className="
                    flex
                    items-center
                    justify-between
                    
                    pt-4
                "
      >
        <div
          className="
                    inline-flex
                    items-center
                    gap-x-2
                "
        >
          <AiFillPhone className="text-neutral-400" size={26} />
          <p
            className="
            text-neutral-400
            font-medium
            text-md
            "
          >Phone App</p>
        </div>
        <AiOutlinePlus
            onClick={onClick}
            size={20}
            className="
                text-neutral-400
                cursor-pointer
                hover:text-white
                transition
            "
        />
      </div>
      <div className="
        flex
        flex-col
        gap-y-2
        mt-4
        px-1

      ">
        <T9Keypad/>
      </div>
    </div>
  );
};

export default PhoneApp;
<div>Library!</div>;
