import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import axios from "axios";
import React from "react";
import Box from "./Box";
import SidebarItem from "./SidebarItem";
import Topbar from "../TopBar/Topbar";
import Bottombar from "../BottomBar/Bottombar";
import BottomBarItem from "../BottomBar/BottomBarItem";
import {
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineUp,
  AiOutlineDown,
} from "react-icons/ai";
import {
  HiHome,
  HiOutlineSpeakerphone,
  HiOutlineNewspaper,
} from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { RiLogoutCircleLine } from "react-icons/ri";
import { SiMarketo } from "react-icons/si";
import { GiDiceShield } from "react-icons/gi";
import {
  BiSearch,
  BiGroup,
  BiPhoneCall,
  BiSolidReport,
  BiTask,
} from "react-icons/bi";
import { PiUserSwitchLight } from "react-icons/pi";
import Dialog from "../Dialog/Dialog";
import PhoneApp from "./PhoneApp";
import AlertItem from "./AlertItem";
import env from "react-dotenv";
import { toast } from "react-toastify";
import { getItem, saveItem, removeItem } from "store/LocalStorage";
import { API_URL, AUTH_URL } from "utils/config";

interface SidebarProps {
  children: React.ReactNode;
}
const Sidebar: React.FC<SidebarProps> = ({ children }) => {
  const pathname = useLocation().pathname;

  const [menuLarge, setMenuLarge] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignIn, setIsSignIn] = useState(
    getItem("isOpenLogin") ? true : false
  );
  const [commit, setCommit] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isOpenSignIn, setIsOpenSignIn] = useState(
    getItem("isOpenLogin") ? true : false
  );
  const [hidden, setHidden] = useState(false);
  const [dataLogin, setDataLogin] = useState({ userName: "", password: "" });
  const [userInfo, setUserInfo] = useState<any>({});

  const handleSignUp = async () => {};
  const handleLogOut = async () => {
    await removeItem("accessToken");
    await removeItem("refreshToken");
    await removeItem("id");
    setIsLogin(false);
    toast("Signed Out", { position: "top-center" });
    setTimeout(() => {
      window.location.reload();
    }, 1 * 1000);
  };
  const handleSignIn = async () => {
    axios
      .request({
        method: "post",
        url: `${AUTH_URL}/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: dataLogin,
      })
      .then(async (res) => {
        const data = res.data.data;
        await saveItem(data);
        // Object.values(data).map((value) => {});
        setIsLogin(!isLogin);
        setIsOpenSignIn(false);
        removeItem("isOpenLogin")
        // window.localStorage && window.localStorage.setItem("isLogin", "true");
        setDataLogin({ userName: "", password: "" });
        toast("User has successfully logged in", { position: "top-center" });
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        setIsOpenSignIn(false);
        toast(error.response?.data.message, { position: "top-center" });
      });
  };

  const handleOnClose = () => {
    setIsOpenSignUp(false);
    setIsOpenSignIn(false);
    setIsSignIn(false);
    setIsSignUp(false);
    setIsPromoCode(false);
  };

  const path = useLocation().pathname;
  const pathHearder = path.split("link-card/")[1];
  // console.log({ pathHearder });

  const loadUser = () => {
    const token = getItem("accessToken");
    const configGetUser = {
      method: "get",
      url: `${AUTH_URL}/user`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (isLogin) {
      axios
        .request(configGetUser)
        .then((res) => {
          setUserInfo(res.data.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const hanldeSwitch = () => {
    saveItem({
      accessToken: getItem("switchAccess"),
      refreshToken: getItem("switchRefresh"),
      switchAccess: "",
      switchRefresh: "",
    });
    window.location.reload();
  };

  useEffect(() => {
    const url = API_URL;
    const token = getItem("accessToken");
    const switchUser = getItem("switchAccess");
    if (switchUser) {
      setIsSwitch(true);
    }
    if (pathHearder) {
      setHidden(true);
    }
    if (token) {
      setIsLogin(true);
      loadUser();
    }
  }, [isLogin]);

  const router = useMemo(
    () => [
      {
        icon: BiGroup,
        label: "Khách hàng",
        active: pathname === "/customers",
        href: "/customers",
      },
      {
        icon: BiTask,
        label: "Nhiệm vụ",
        active: pathname === "/task",
        href: "/task",
      },
      {
        icon: BiSolidReport,
        label: "Báo cáo",
        active: pathname === "/report",
        href: "/report",
      },
      {
        icon: BiPhoneCall,
        label: "CDR",
        active: pathname === "/cdr",
        href: "/cdr",
      },
    ],
    [pathname]
  );

  return (
    <div className="flex flex-row h-[100vh] overflow-hidden">
      <Dialog
        isOpen={isOpenSignUp || isOpenSignIn}
        onClose={handleOnClose}
        autoWidth
      >
        <div className="flex flex-col md:flex-row">
          <div className="image-panel h-[200px] md:w-[400px] md:h-auto block overflow-hidden">
            <img
              // src="/logo_bgroup.jpg"
              src="https://img.freepik.com/premium-photo/finance-money-technology-background-concept-business-prosperity-asset-management-creative-graphic-show-economy-financial-growth-generative-ai_438099-20149.jpg?w=1380"
              alt="banner-img"
              width="auto"
              height="auto"
              object-fit="cover"
              className="h-full w-full"
            />
          </div>
          <div
            className={`content-signin-signup flex flex-col h-full mx-[50px] my-[30px] md:w-[52%] gap-4 md:translate-y-0 ${
              isSignIn ? "translate-y-[-25%]" : "translate-y-[-15.5%]"
            }`}
          >
            <div className="button-signup-signin flex flex-row p-2 w-[70%] bg-neutral-950 rounded-2xl mx-auto md:ml-0">
              <button
                className={`button-signup py-4 w-full rounded-xl ${
                  isSignUp ? "primary-color font-bold" : ""
                }`}
                onClick={() => {
                  setIsSignIn(false);
                  setIsSignUp(true);
                }}
              >
                Đăng Ký
              </button>
              <button
                className={`button-signup py-4 w-full rounded-xl ${
                  isSignIn ? "primary-color font-bold" : ""
                }`}
                onClick={() => {
                  setIsSignUp(false);
                  setIsSignIn(true);
                }}
              >
                Đăng Nhập
              </button>
            </div>
            <div
              className={`signup-content flex flex-col gap-4 ${
                !isSignUp ? "hidden" : ""
              }`}
            >
              <input
                type="text"
                id="inputt"
                className="py-5 px-6 rounded-2xl"
                placeholder="Enter Your Email"
                aria-label="Email"
              />
              <input
                type="text"
                className="py-5 px-6 rounded-2xl"
                placeholder="Username"
                aria-label="Username"
              />
              <input
                type="password"
                className="py-5 px-6 rounded-2xl"
                placeholder="Enter Your Password"
                aria-label="Enter Your Password"
              />
              <div className="enter-promo-code flex flex-col gap-2">
                <div className="flex flex-row gap-2 items-center">
                  <p className="font-bold">Nhập mã giới thiệu</p>
                  <button
                    className={`p-2 bg-neutral-800 hover:bg-neutral-600 rounded-full ${
                      isPromoCode ? "" : "hidden"
                    }`}
                    onClick={() => setIsPromoCode(false)}
                  >
                    <AiOutlineUp size={11} />
                  </button>
                  <button
                    className={`p-2 bg-neutral-800 hover:bg-neutral-600 rounded-full ${
                      !isPromoCode ? "" : "hidden"
                    }`}
                    onClick={() => setIsPromoCode(true)}
                  >
                    <AiOutlineDown size={11} />
                  </button>
                </div>
                <input
                  type="text"
                  className={`py-5 px-6 w-[50%] rounded-2xl ${
                    !isPromoCode ? "hidden" : ""
                  }`}
                  placeholder="Write Promo Code"
                  onChange={(e) => setPromoCode(e.target.value)}
                />
              </div>
              <div className="commit-age flex flex-row gap-4 text-sm text-neutral-300">
                <div>
                  <input
                    type="checkbox"
                    name="agree"
                    value="yes"
                    onChange={(e) => setCommit(e.target.value)}
                  />
                </div>
                <div>
                  <p>I am 18+</p>
                  <p></p>
                </div>
              </div>
              <div className="button-signup shadow-box rounded-full">
                <button
                  className="p-4 w-full rounded-full font-bold text-neutral-950 bg-yellow-400"
                  onClick={() => handleSignUp()}
                >
                  Đăng Ký
                </button>
              </div>
            </div>
            <div
              className={`signin-content flex flex-col gap-4 ${
                !isSignIn ? "hidden" : ""
              }`}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  handleSignIn();
                }
              }}
            >
              <input
                type="text"
                id="input"
                value={dataLogin.userName}
                onChange={(e) => {
                  setDataLogin({ ...dataLogin, userName: e.target.value });
                }}
                className="py-5 px-6 rounded-2xl"
                placeholder="Usename or Email"
                aria-label="Email"
              />
              <input
                type="password"
                value={dataLogin.password}
                onChange={(e) => {
                  setDataLogin({ ...dataLogin, password: e.target.value });
                }}
                className="py-5 px-6 rounded-2xl"
                placeholder="Enter Your Password"
                aria-label="Enter Your Password"
              />
              <div className="button-signin shadow-box rounded-full">
                <button
                  className="p-4 w-full rounded-full font-bold text-neutral-950 bg-yellow-400"
                  onClick={() => handleSignIn()}
                >
                  Đăng Nhập
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <div
        className={`
                left-bar
                border-right
                flex-col
                gap-y-2
                primary-color
                h-full
                w-full                
                py-2
                ${
                  hidden
                    ? "hidden"
                    : menuLarge
                    ? "lg:flex lg:w-[210px]"
                    : "hidden lg:flex lg:w-[70px]"
                }
        `}
      >
        <div
          className={`p-2 border-bottom h-[63px] flex ${
            !menuLarge ? "justify-center" : "justify-between"
          } `}
        >
          <Link
            to={"/"}
            onClick={() => setMenuLarge(false)}
            className={`flex flex-row justify-between items-center gap-2 ${
              !menuLarge ? "hidden" : ""
            }`}
          >
            <div className="icon-home p-3 rounded-full bg-neutral-800">
              <HiHome size={20} />
            </div>
            <p className="text-yellow-500 font-bold hidden md:flex">
              {userInfo?.company?.name}
            </p>
          </Link>
          <button
            className="p-2 text-center"
            onClick={() => {
              setMenuLarge(!menuLarge);
            }}
          >
            <AiOutlineMenuUnfold
              size={24}
              className={`rotateY ${menuLarge ? "hidden" : ""}`}
            />
            <AiOutlineMenuFold
              size={24}
              className={`rotateY ${menuLarge ? "" : "hidden"}`}
            />
          </button>
        </div>
        <Box>
          <div
            className={`router-item flex flex-col gap-y-5 px-3.5 py-4 ${
              !menuLarge ? "mt-[100px]" : ""
            }`}
          >
            {router.map((item: any) => (
              <SidebarItem
                key={item.label}
                {...item}
                label={menuLarge ? item.label : ""}
              />
            ))}
          </div>
        </Box>
        <Box>
          <div
            className={`
            hidden
            lg:flex
            flex-col
            gap-y-5
            px-3.5
            py-4
            ${!menuLarge ? "lg:hidden" : ""}
            `}
          >
            <PhoneApp />
          </div>
        </Box>
      </div>
      <div
        className={`main flex-col w-full h-full relative overflow-auto ${
          menuLarge ? "hidden lg:flex" : "flex"
        }`}
      >
        <div
          className={`main-top flex flex-col bg-gradient-to-b ${
            userInfo?.company?.color === "green"
              ? "from-green-600"
              : userInfo?.company?.color === "red"
              ? "from-red-600"
              : userInfo?.company?.color === "blue"
              ? "from-blue-600"
              : userInfo?.company?.color === "orange"
              ? "from-orange-600"
              : userInfo?.company?.color === "violet"
              ? "from-violet-600"
              : userInfo?.company?.color === "pink"
              ? "from-pink-600"
              : "from-green-600"
          } ${hidden ? "hidden" : ""}`}
        >
          <Topbar>
            <div className="top-bar-content flex flex-row justify-between w-full mx-6 gap-10">
              <div
                className={`top-bar-left w-full flex flex-row ${
                  menuLarge ? "hidden" : ""
                }`}
              >
                <div className="alert-item w-full max-w-[400px] my-auto">
                  <AlertItem
                    // image="/logo_bgroup.jpg"
                    image={userInfo?.company?.image}
                    name={userInfo?.company?.name}
                    text="Thông báo !!!"
                    href="alert"
                  />
                </div>
                {/* <Link
                  to={"/"}
                  onClick={() => setMenuLarge(false)}
                  className={`flex flex-row justify-between items-center gap-2`}
                >
                  <div className="icon-home p-3 rounded-full bg-neutral-800">
                    <HiHome size={20} />
                  </div>
                  <p className="text-yellow-500 font-bold text-xl uppercase hidden md:flex">
                    {userInfo?.company?.name}
                  </p>
                </Link> */}
              </div>
              {/* <div className="top-bar-center hidden lg:flex flex-row gap-6"></div> */}
              <div
                className={`top-bar-right none-login flex flex-row gap-6 ${
                  isLogin ? "hidden" : ""
                }`}
              >
                <div className="top-bar-right-end flex flex-row gap-6 my-auto">
                  {/* <div className="rounded-full hover:bg-neutral-800">
                    <button
                      className="flex flex-row gap-3 items-center justify-center my-auto mx-6 h-full"
                      onClick={() => {}}
                    >
                      <p className="font-bold">Light</p>
                    </button>
                  </div> */}
                  <div className="shadow-box rounded-full h-fit px-5 bg-red-600/60 ">
                    <button
                      className="w-fit h-fit truncate flex flex-row gap-3 items-center justify-center my-auto"
                      onClick={() => {
                        setIsOpenSignIn(true);
                        setIsSignIn(true);
                      }}
                    >
                      <p className="font-bold my-3 ">Đăng nhập</p>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`top-bar-right is-login flex flex-row gap-6 my-auto ${
                  !isLogin ? "hidden" : ""
                }`}
              >
                <div className="top-bar-right-end h-fit flex flex-row gap-6">
                  <div
                    className={`rounded-full hover:bg-cyan-600 ${
                      !isSwitch ? "hidden" : ""
                    }`}
                  >
                    <button
                      className="flex flex-row gap-3 items-center justify-center my-auto p-4 h-full"
                      onClick={() => {
                        hanldeSwitch();
                      }}
                    >
                      <PiUserSwitchLight size={15} />
                    </button>
                  </div>
                  <div className="rounded-full h-fit hover:bg-red-600/70">
                    <button
                      className="flex flex-row gap-3 items-center justify-center my-auto p-4 h-full"
                      onClick={() => {
                        handleLogOut();
                      }}
                    >
                      <RiLogoutCircleLine size={15} />
                    </button>
                  </div>
                  <div className="top-bar-right-item h-fit rounded-full bg-neutral-800/50">
                    <Link
                      to={"/account"}
                      className="h-full flex flex-col justify-center p-4"
                    >
                      <FaUserAlt size={15} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Topbar>
        </div>
        <main
          className={`main w-full ${
            hidden ? "h-full" : "h-[calc(100%-160px)] lg:h-full"
          } ${
            isSwitch ? "shadow-[inset_0_0px_10px_1px_rgba(255,0,0)]" : ""
          } lg:h-full overflow-y-auto overflow-x-hidden py-2 scrollbar-small`}
        >
          {children}
        </main>
        <div
          className={`bottom-bar w-full lg:hidden bottom-0 fixed bg-neutral-900  ${
            hidden ? "hidden" : ""
          }`}
        >
          <Bottombar>
            <div className="bottom-bar-content border-top w-full h-full flex flex-row justify-evenly items-center gap-4">
              <button
                className="bottom-bar-button flex flex-col gap-2 justify-center"
                onClick={() => setMenuLarge(!menuLarge)}
              >
                <AiOutlineMenuUnfold
                  size={24}
                  className={!menuLarge ? "" : "hidden"}
                />
                <p className="button-title">Menu</p>
              </button>
              {router.map((item: any) => (
                <BottomBarItem key={item.label} {...item} />
              ))}
            </div>
          </Bottombar>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
