import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

html,
body,
:root {
  font-size: 12px;
  height: 100%;
  background-color: #161617;
  color-scheme: dark;
}

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }


  p,
  label {
    font-family: 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
    background-color: #161617;
    border: 0.5px solid rgba(58,59,60,.4);
    outline-color: yellow;
  }

  .primary-color {
    background-color: rgb(27 27 31);
  }
  .secondary-color {
    background-color: rgb(42 42 47);
  }

  .dialog {
    z-index: 100;
  }
  /* .dialog-2 {
    z-index: 1000;
  } */

  .dialog-content {
    background-color: #161617;
  }

  .border-color {
    border: 0.5px solid rgba(58,59,60,.4);
  }
  .border-top {
    border-top: 0.5px solid rgba(58,59,60,.4);
  }
  .border-bottom {
    border-bottom: 0.5px solid rgba(58,59,60,.4);
  }
  .border-right {
    border-right: 1px solid rgb(33 33 34);
  }

  .scrollbar-small::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.5rem;
  /* background-color: green; */
}
  .scrollbar-small::-webkit-scrollbar-thumb {
  background-color: rgb(82 82 82);
  border-radius: 1rem;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0.1em; /* Chiều rộng của thanh cuộn */
  height: 0.5em; /* Chiều cao của thanh cuộn */
  /* background-color: green; */
}
/* .scrollbar-hidden::-webkit-scrollbar-thumb {
  background-color: #888;
  height: 0.5em;
} */
.run-text {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

.spin {
  border: 6px solid white;
  border-color: white transparent white transparent;
  border-radius: 50%;
  animation: loading 1s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(1200deg); }
}

  .sidebar-item:hover {
    transition:0.6s;
    background-color: rgb(42 42 47);
  }
  .top-bar-right-item:hover {
    transition:0.6s;
    background-color: rgb(82 82 82);
  }
  .sidebar-item:hover .icon {
    transition:0.6s;
    color: rgb(234 179 8);
  }
  .top-bar-center-button:hover .icon {
    transition:0.6s;
    color: rgb(234 179 8);
  }

  /* .list-link-card:hover .remove-item {
    display: block
  } */

  .shadow-box:hover {
    transition:0.2s;
    box-shadow: 0px 0px 5px;
  }

  .rotateZ:hover {
    transform: rotate(90deg);
    transition: transform 1s;
  }

  .rotateY:hover {
    transform: rotateY(180deg);
    transition: transform 1s;
  }
  
  .rotateX:hover {
    transform: rotateX(90deg);
    transition: transform 1s;
  }

  .item:hover {
    transition: .1s;
    transform: scale(1.005, 1.02);   
  }

  .copied {
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 33% 100%, 50% 75%, 0% 75%);
  }
  .main {
    background-image: url('../../galaxy_background.png');
    background-size: cover;
  }

  .show-img {
    background: url('../../galaxy_background.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
